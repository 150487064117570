@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100vw;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100vw;
}

.main {
  background-color: #ece6dd;
  min-height: 100vh;
  width: 100vw;
  color: #0A0A0A;
}

.flex {
  display: flex;
  align-items: center;
}

.together {
  display: flex;
  justify-content: space-around;
}

.separate {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spread-evenly {
  display: flex;
  justify-content: space-evenly;
}

.break {
  height: 1em;
}

.left {
  display: flex;
  justify-content: left;
  gap: 0.5em;
  padding: 0.5em;
}

/* COLORS */

.color1 {
  color: #0A0A0A
}

.color2 {
  color: #FEFEFE;
}

.color3 {
  color: #585757;
}


.bg1 {
  background-color: #0A0A0A;
}

.bg2 {
  background-color: #FEFEFE;
}

.bg3 {
  background-color: #e0e0ed;
}

.bg4 {
  background-color: #F6F6F8;
}

.no-bg {
  background-color: rgba(0, 0, 0, 0);
}

.dark {
  color: #0A0A0A;
}

.light {
  color: #FEFEFE;
}

.red {
  color: red;
}

.blue {
  color: #0f0fa2;
}

/*  */

.font1 {
  font-family: 'Poppins', sans-serif;
}

.font2 {}

.font3 {}

/*  */

.border1 {
  border: 1px solid #FEFEFE;
}

.border2 {
  border: 1px solid #0a0a0a;
}

.border3 {
  border: 1px solid #c2c2cb;
}

.no-border {
  border: 0;
}

.border-top1 {
  border: 0;
  border-top: 1px solid #0a0a0a;
}

.border-bottom1 {
  border: 0;
  border-bottom: 1px solid #0A0A0A;
}

.border-vert1 {
  border: 0;
  border-top: 1px solid #0A0A0A;
  border-bottom: 1px solid #0A0A0A;
}

.border-red {
  border: 1px solid red;
}

/*  */
.divider {
  border: 0;
  border-bottom: 1px solid #b0b0b0;
  width: 60%;
  margin: 1em 0;
}

.padding {
  padding: 1em;
}


@media only screen and (min-width: 350px) {
  .top {
    padding: 1em;
    display: flex;
    justify-content: space-between;
  }

  .top img {
    width: 18vw;
  }

  .top-icon {
    font-size: 2em;
  }

  .page-title {
    font-weight: 600;
    font-size: 3.4em;
    letter-spacing: -3px;
  }

  .page-subtitle {
    font-size: 1.2em;
    font-weight: 300;
    letter-spacing: -1px;
  }

  .page-title-sm {
    font-size: 2.3em;
    text-align: left;
  }

}

@media only screen and (min-width: 600px) {
  .top img {
    width: 10vw;
  }

  .top-icon {
    font-size: 1.8em;
  }

  .page-title {
    text-align: center;
  }

  .page-subtitle {
    text-align: center;
    font-size: 1.3em;
  }
}

@media only screen and (min-width: 800px) {
  .top img {
    width: 7vw;
  }

  .top-icon {
    font-size: 1.6em;
  }

  .page-title {
    font-size: 4em;
  }

  .page-subtitle {
    font-size: 1.4em;
  }
}

@media only screen and (min-width: 1000px) {
  .top img {
    width: 6vw;
  }

  .top-icon {
    font-size: 1.8em;
  }

  .page-title {
    font-size: 4.5em;
    line-height: 80px;
  }

  .page-subtitle {
    font-size: 1.7em;
  }
}

@media only screen and (min-width: 1200px) {
  .top img {
    width: 5vw;
  }

  .top-icon {
    font-size: 2em;
  }

  .page-title {
    font-size: 5em;
  }
}