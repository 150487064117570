/* FONTS
    font-family: 'Roboto', sans-serif;
*/
/* COLORS
    Light Blue - 57b4ee
    Blue - 5783d6
    Yellow - F4E04D
    Lighter Blue - 54F2F2
    Dark - #0A0A0A
    Light - #FEFEFE
*/

@media only screen and (min-width: 350px) {
    .dashboard-head {
        padding: 0.5em;
    }

    .dash-out {
        font-size: 0.8em;
        background-color: red;
        padding: 0.5em 1.5em;
        height: fit-content;
        border-radius: 5px;
    }

    .dashboard-wrap {
        display: grid;
    }

    .dash-comp {
        padding: 1em;
        height: 300px;
        overflow-y: scroll;
        border: 1px solid black;
        margin: 1em;
    }

    .dash-comp h1 {
        font-size: 1.3em;
    }

    .dash-comp-desc {
        font-weight: 300;
        font-size: 0.8em;
        color: gray;
        margin-bottom: 0.8em;
    }

    .dash-pageviews-block {
        display: flex;
        width: 100%;
        padding: 0.2em 0.5em;
    }

    .dash-pageviews-block h3 {
        font-weight: 300;
        font-size: 0.9em;
    }

    .dash-pageviews-block p {
        font-size: 0.9em;
        margin-right: 0.5em;
    }

    .page-views {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.4em;
    }

    .dash-contact-table {
        overflow-x: scroll;
    }

    .dash-contact-table tr {
        text-align: left;
        padding: 1em 0;
    }

    .dash-contact-table tr th {
        font-weight: 400;
        height: 40px;
        font-size: 0.9em;
        padding: 0.4em;
        margin: 0.1em; 
        width: fit-content;
    }

    .dash-contact-table tr td {
        font-weight: 300;
        font-size: 0.9em;
        border: 1px solid #111;
        white-space: nowrap;
        padding: 0.4em;
        max-width: fit-content;
    }

    .dash-contact-msg {
        min-height: 200px;
    }

}

@media only screen and (min-width: 600px) {
    .dashboard-wrap {
        grid-template-columns: repeat(2, 1fr);
    }

    .page-views {
        grid-template-columns: 1fr;
    }

    .col1 {
        grid-column: span 1;
    }

    .col2 {
        grid-column: span 2;
    }

    .col3 {
        grid-column: span 3;
    }

    .col4 {
        grid-column: span 4;
    }

}

@media only screen and (min-width: 800px) {
    .page-views {
        grid-template-columns: 1fr 1fr;
    }

    .dashboard-wrap {
        grid-template-columns: repeat(2, 1fr);
    }

}

@media only screen and (min-width: 1000px) {
    .dashboard-wrap {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (min-width: 1200px) {
    .dashboard-wrap {
        grid-template-columns: repeat(4, 1fr);
    }
}