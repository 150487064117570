@media only screen and (min-width: 350px) {
    .about {
        padding: 1em;
    }
    .about-wrap {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
    }
    .about-section {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5em;
        margin-top: 1em;
    }

    .about-img {
        background-image: url('../PHOTOS/john.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 30vh;
        width: 100%;
    }

    .about-sub {
        font-size: 2.2em;
        letter-spacing: -2px;
        padding: 0.5em 0;
        font-weight: 400;
        text-align: center;
    }

    .about-section p {
        font-size: 1em;
        font-weight: 300;
        padding: 0.4em 0;
    }
}

@media only screen and (min-width: 600px) {
    .about-section {
        grid-template-columns: 40% 60%;
        gap: 1em;
    }
    .about-sub {
        font-size: 1.6em;
    }
    .about-text {
        padding: 1em;
    }
}

@media only screen and (min-width: 800px) {
    .about-section p {
        font-size: 1em;
    }
    .about-img {
        height: 40vh;
    }

}

@media only screen and (min-width: 1000px) {
    /* .about-sub {
        letter-spacing: -2px;
    } */
    .about-img {
        height: 45vh;
    }
}

@media only screen and (min-width: 1200px) {
    .about-section {
        width: 80vw;
        margin: 0 auto;
        grid-template-columns: 1fr 1fr;
    }

    .about-sub {
        font-size: 3em;
        letter-spacing: -4px;
    }

    .about-img {
        height: 55vh;
    }
    .about-section p {
        font-size: 1.2em;
        padding: 0.6em 0;
    }
}